import moment from 'moment/moment';

export const useContainerLogsStore = defineStore('containerLogsStore', {
  state: () => ({
    containers: [],
    total: 0,
    page: 1,
  }),
  actions: {
    async fetchContainerLogs(routeQuery: any) {
      const { $ofetch }: any = useNuxtApp();
      let logFilterBy: string = '';

      if (routeQuery.tab && routeQuery.tab !== 'ALL') {
        logFilterBy = `&&validation_error_type:[${routeQuery.tab}]`;
      }
      if (routeQuery.tab === 'RECENT_UPLOAD') {
        logFilterBy = `&&created:[${moment().subtract(1, 'days').unix()}..${moment().unix()}]`;
      }

      const userStore = useUserStore();
      const queryParams: any = {
        q: routeQuery.q || '*',
        page: routeQuery.page || 1,
        filter_by: `org_id:=${userStore.activeOrgId}${logFilterBy}`,
      };
      const queryString = new URLSearchParams(queryParams).toString();
      const url = `/api/container_logs?${queryString}`;
      const { results, total, page }: any = await $ofetch(url, { method: 'GET' });
      this.containers = results;
      this.total = total;
      this.page = page;
      return results;
    },

  },
});
